import { C as CommonWidgetCarousel } from "./CommonWidgetCarousel-chunk.js";
import "./index-chunk.js";
import "./index-chunk2.js";
import "./Controller-chunk.js";
import "./Utils-chunk.js";
import "./Media-chunk.js";
const Quinn = window.Quinn;
Quinn.functions.renderCards = ({
  playlistId,
  sectionId,
  metaObject,
  mediaCount
}) => {
  const target = document.getElementById(sectionId);
  if (!target) {
    return;
  }
  new CommonWidgetCarousel({
    target,
    props: {
      playlistId,
      widgetType: "cards",
      metaObject,
      mediaCount
    }
  });
  const { show_branding } = Quinn.settings.general;
  if (show_branding === false)
    return;
  const logoHtml = window.Quinn.functions.getQuinnBranding({
    utm_source: "outside_overlay",
    utm_medium: Quinn.shop_domain.split(".")[0]
  });
  const logoContainer = document.createElement("div");
  logoContainer.style.left = "5px";
  logoContainer.innerHTML = logoHtml;
  target.parentNode.appendChild(logoContainer);
};
